import React, { useState } from 'react';
import { Card, CardHeader, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ClientRating } from '../components/dashboard/ClientRatings';
import { NumberOfSurveys } from '../components/dashboard/NumberOfSurveys';
import { OverallPerformance } from '../components/dashboard/OverallPerformance';
import { DataTable } from '../components/shared/DataTable';
import { CitizenExperiences } from '../components/dashboard/CitizenExperiences';
import { EntityTable } from '../components/dashboard/EntityTable';

import semaLogo from '../images/sema.png';
import nitaULogo from '../images/nita-u.png';

const Dashboard = () => {
  const [timeFilter, setTimeFilter] = useState('month');
  const [entityType, setEntityType] = useState('MDA/ Entity|Civic Society Organisation|Private Entity|Citizen');

  return (
    <Container maxWidth="xl" sx={{ py: '30px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card sx={{ display: 'flex', justifyContent: 'center', bgcolor: '#C9FBCB' }}>
            <Box sx={{ width: '950px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', rowGap: '10px' }}>
                <img src={semaLogo} height="50px" alt="Sema Logo" />
                <Typography variant="h4">We collect citizen feedback about public services</Typography>
                <img src={nitaULogo} height="40px" alt="Sema Logo" />
              </Box>
            </Box>
          </Card>
          <Card sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '950px' }}>
              <Select
                name="clientBody"
                sx={{ height: '40px', my: '5px', mr: '15px' }}
                defaultValue="month"
                onChange={(e) => setTimeFilter(e.target.value)}
              >
                <MenuItem value="day">daily</MenuItem>
                <MenuItem value="week">weekly</MenuItem>
                <MenuItem value="month">monthly</MenuItem>
                <MenuItem value="quater">quaterly</MenuItem>
                <MenuItem value="year">yearly</MenuItem>
              </Select>

              <Select
                name="clientBody"
                sx={{ height: '40px', my: '5px' }}
                defaultValue="MDA/ Entity|Civic Society Organisation|Private Entity|Citizen"
                onChange={(e) => setEntityType(e.target.value)}
              >
                <MenuItem value="MDA/ Entity|Civic Society Organisation|Private Entity|Citizen">All</MenuItem>
                <MenuItem value="MDA/ Entity">MDA/ Entity</MenuItem>
                <MenuItem value="Civic Society Organisation">Civic Society Organisation</MenuItem>
                <MenuItem value="Private Entity">Private Entity</MenuItem>
                <MenuItem value="Citizen">Citizen</MenuItem>
              </Select>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <ClientRating timeFilter={timeFilter} entityType={entityType} />
        </Grid>

        <Grid item xs={12} md={6}>
          <NumberOfSurveys timeFilter={timeFilter} entityType={entityType} />
        </Grid>

        <Grid item xs={12} md={6}>
          <CitizenExperiences timeFilter={timeFilter} entityType={entityType} />
        </Grid>

        <Grid item xs={12} md={6}>
          <OverallPerformance timeFilter={timeFilter} entityType={entityType} />
        </Grid>

        <Grid item xs={12} md={6}>
          <DataTable
            timeFilter={timeFilter}
            entityType={entityType}
            heading="Categories"
            endpoint="/api/statistic/categoryStatistics"
            headerBGColor="#F3992B"
            type="categoryStatistics"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DataTable
            heading="Entity"
            headerBGColor="#F23A3C"
            timeFilter={timeFilter}
            entityType={entityType}
            endpoint="/api/statistic/entityStatistics"
            type="entityStatistics"
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ height: '100%' }}>
            <CardHeader title="Entity List" sx={{ bgcolor: '#868685', textAlign: 'left' }} />
            <EntityTable timeFilter={timeFilter} entityType={entityType} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
