import axios from 'axios';

// content type
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

// intercepting to capture errors
instance.interceptors.response.use(
  (response) => {
    return {
      status: response.data.status,
      message: response.data.message,
      data: response.data ? response.data.data : null,
    };
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    // Happens when axios fails due to cors errors or axios fails to reach server due to internet problems
    if (error.message === 'Network Error' || !error.response) {
      message = navigator.onLine
        ? 'Network Error! Please contact your service provider'
        : 'Network Error! Please check your internet connection';
      return {
        success: false,
        message,
        data: null,
      };
    }

    switch (error.response.status) {
      case 400:
        message = 'Bad Request';
        break;
      case 401:
        message = 'Invalid credentials! Please log in again';
        break;
      case 403:
        message = 'Access Forbidden';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default: {
        message = error.response && error.response.data ? error.response.data['message'] : error.message || error;
      }
    }
    return {
      success: error.response.data.success,
      message,
      data: error.response ? error.response : null,
    };
  }
);

class APICore {
  /**
   * Fetches data from given url
   */
  getAPI = (url, params) => {
    return instance.get(`${url}`, params);
  };

  getFileAPI = (url, params) => {
    var queryString = params
      ? Object.keys(params)
          .map((key) => key + '=' + params[key])
          .join('&')
      : '';
    return instance.get(`${url}?${queryString}`, { responseType: 'blob' });
  };

  /**
   * post given data to url
   */
  postAPI = (url, data) => {
    return instance.post(url, data);
  };

  /**
   * post given data to url with file
   */
  postAPIWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return instance.post(url, formData, config);
  };

  /**
   * Updates patch data
   */
  patchAPI = (url, data) => {
    return instance.patch(url, data);
  };

  /**
   * Updates data
   */
  putAPI = (url, data) => {
    return instance.put(url, data);
  };

  /**
   * Deletes data
   */
  deleteAPI = (url) => {
    return instance.delete(url);
  };
}

export { APICore };
