import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getRatingDistribution } from '../../apis/app.service';

export const ClientRating = ({ timeFilter, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: ['ratingDistribution'],
    queryFn: () => getRatingDistribution({ params: { timeFilter, entityType } }),
  });
  const chartRef = useRef(null);
  useEffect(() => {
    refetch();
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(data.series, data.categories));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, refetch, timeFilter, entityType]);

  if (data) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Client service ratings" sx={{ textAlign: 'center' }} />
        <CardContent>
          <div ref={chartRef}></div>
        </CardContent>
      </Card>
    );
  }
};

const getChartOptions = (series, categories) => {
  const labelColor = '#A1A5B7';
  const borderColor = '#A1A5B7';

  return {
    series,
    chart: {
      type: 'bar',
      fontFamily: 'inherit',
      stacked: true,
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '95%',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      max: 100,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    colors: ['#DB2524', '#EA8C28', '#CDCF00', '#7EB147', '#00803D'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 0,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 5,
      },
    },
  };
};
