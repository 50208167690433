import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';

import { getClientList } from '../../apis/app.service';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
  },
];

const entries = [
  {
    name: 'West Acholi Cooperative',
    type: 'Civic Society Organisation',
    contact: '+256753000343',
  },
  {
    name: 'SEMA',
    type: 'Private Entity',
    contact: '+256753386675',
  },
  {
    name: 'Directorate of Citizenship and Immigration Control of Uganda',
    type: 'MDA/ Entity',
    contact: '+256773311649',
  },
];

export const EntityTable = ({ timeFilter, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: ['clientList'],
    queryFn: () => getClientList({ params: { timeFilter, entityType } }),
  });

  console.log(data);

  useEffect(() => {
    refetch();
  }, [data, refetch, timeFilter, entityType]);

  if (data) {
    return <Table columns={columns} dataSource={data} />;
  }
};
