import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getPieRatingDistribution } from '../../apis/app.service';

export const OverallPerformance = ({ timeFilter, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: ['pieRatingDistribution'],
    queryFn: () => getPieRatingDistribution({ params: { timeFilter, entityType } }),
  });

  const chartRef = useRef(null);
  useEffect(() => {
    refetch();
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(data));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, refetch, timeFilter, entityType]);

  if (data) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Client service ratings" sx={{ textAlign: 'center' }} />
        <CardContent>
          <div ref={chartRef}></div>
        </CardContent>
      </Card>
    );
  }
};

const getChartOptions = (series) => {
  return {
    chart: {
      type: 'donut',
      height: 250,
    },
    series,
    colors: ['#DB2524', '#EA8C28', '#CDCF00', '#7EB147', '#00803D'],
    labels: ['Very Bad', 'Bad', 'Average', 'Good', 'Very good'],
    plotOptions: {
      pie: {
        expandOnClick: true,
        toolbar: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'top',
          },
        },
      },
    ],
  };
};
const pieChartOptions = {
  chart: {
    type: 'donut',
    height: 250,
  },
  series: [44, 55, 13, 33, 32],
  colors: ['#DB2524', '#EA8C28', '#CDCF00', '#7EB147', '#00803D'],
  labels: ['Very Bad', 'Bad', 'Average', 'Good', 'Very good'],
  plotOptions: {
    pie: {
      expandOnClick: true,
      toolbar: {
        show: true,
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'top',
        },
      },
    },
  ],
};
