import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from '@tanstack/react-query';

import { getTableData } from '../../apis/app.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F5F8FA',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const DataTable = ({ heading, headerBGColor, timeFilter, type, endpoint, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: [type],
    queryFn: () => getTableData(endpoint, { params: { timeFilter, entityType } }),
  });

  useEffect(() => {
    refetch();
  }, [data, refetch, timeFilter, entityType]);
  if (data) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title={heading} sx={{ bgcolor: headerBGColor, textAlign: 'left' }} />
        <CardContent sx={{ p: '0px' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell align="right">Satisfaction</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow key={row.key}>
                    <StyledTableCell component="th" scope="row">
                      {row.key}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.value}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  }
};
