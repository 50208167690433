import { Typography, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';

const checkBoxStyles = {
  color: '#AFC737',
  '&.Mui-checked': {
    color: '#AFC737',
  },
};

export const REGSection = () => {
  const formik = useFormikContext();

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (formik.values.service.includes(newValue)) {
      formik.values.service = formik.values.service.filter((value) => value !== newValue);
    } else {
      formik.values.service = [...formik.values.service, newValue];
    }
  };

  return (
    <FormGroup sx={{ display: 'block', width: '100%', mb: '20px' }}>
      <Typography>Which service have you received/were you intending to receive?*</Typography>
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="IT Certification"
        value="IT Certification"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Information Security Service"
        value="Information Security Service"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Regulatory Service"
        value="Regulatory Service"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Software Licensing Service"
        value="Software Licensing Service"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Request for a service"
        value="Request for a service"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Data Protection"
        value="Data Protection"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Give feedback"
        value="Give feedback"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      {formik.errors.service && formik.touched.service && (
        <Typography sx={{ color: '#F23A3C' }}>{formik.errors.service}</Typography>
      )}
    </FormGroup>
  );
};

export const NBISection = () => {
  const formik = useFormikContext();
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (formik.values.service.includes(newValue)) {
      formik.values.service = formik.values.service.filter((value) => value !== newValue);
    } else {
      formik.values.service = [...formik.values.service, newValue];
    }
  };

  return (
    <FormGroup sx={{ display: 'block', width: '100%', mb: '20px' }}>
      <Typography>Which service have you received/were you intending to receive?*</Typography>
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Leased Lines"
        value="Leased Lines"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Dark Fibre"
        value="Dark Fibre"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Bulk Internet"
        value="Bulk Internet"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="MyUG Wifi"
        value="MyUG Wifi"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      {formik.errors.service && formik.touched.service && (
        <Typography sx={{ color: '#F23A3C' }}>{formik.errors.service}</Typography>
      )}
    </FormGroup>
  );
};

export const DATSection = () => {
  const formik = useFormikContext();
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (formik.values.service.includes(newValue)) {
      formik.values.service = formik.values.service.filter((value) => value !== newValue);
    } else {
      formik.values.service = [...formik.values.service, newValue];
    }
  };

  return (
    <FormGroup sx={{ display: 'block', width: '100%', mb: '20px' }}>
      <Typography>Which service have you received/were you intending to receive?*</Typography>
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Mobile backend as a Service(BasS)"
        value="Mobile backend as a Service(BasS)"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Infrastructure as a Service(Iaas)"
        value="Infrastructure as a Service(Iaas)"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Co-Location"
        value="Co-Location"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Data Centres"
        value="Data Centres"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      {formik.errors.service && formik.touched.service && (
        <Typography sx={{ color: '#F23A3C' }}>{formik.errors.service}</Typography>
      )}
    </FormGroup>
  );
};

export const EGOVSection = () => {
  const formik = useFormikContext();
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (formik.values.service.includes(newValue)) {
      formik.values.service = formik.values.service.filter((value) => value !== newValue);
    } else {
      formik.values.service = [...formik.values.service, newValue];
    }
    console.log(formik.values.service);
  };

  return (
    <FormGroup sx={{ display: 'block', width: '100%', mb: '20px' }}>
      <Typography>Which service have you received/were you intending to receive?*</Typography>
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="UMCS"
        value="UMCS"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="System and App Development"
        value="System and App development"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="E-Solution for the public"
        value="E-Solution for the public"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="E-Solution in the pandemic"
        value="E-Solution in the pandemic"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />

      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="UGhub Platform"
        value="UGhub Platform"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="UGpass"
        value="UGpass"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />

      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Website Development"
        value="Website Development"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />

      {formik.errors.service && formik.touched.service && (
        <Typography sx={{ color: '#F23A3C' }}>{formik.errors.service}</Typography>
      )}
    </FormGroup>
  );
};

export const ITSection = () => {
  const formik = useFormikContext();
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (formik.values.service.includes(newValue)) {
      formik.values.service = formik.values.service.filter((value) => value !== newValue);
    } else {
      formik.values.service = [...formik.values.service, newValue];
    }
    console.log(formik.values.service);
  };

  return (
    <FormGroup sx={{ display: 'block', width: '100%', mb: '20px' }}>
      <Typography>Which service have you received/were you intending to receive?*</Typography>
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="CERTUG"
        value="CERTUG"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="GOU IT Service Desk"
        value="GOU IT Service Desk"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="Technical Service"
        value="Technical Service"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="IT Advisory Services"
        value="IT Advisory Services"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      <FormControlLabel
        control={<Checkbox sx={checkBoxStyles} />}
        label="IT Capacity Building & Training"
        value="IT Capacity Building & Training"
        onChange={handleChange}
        sx={{ width: 1 / 3, m: 0 }}
      />
      {formik.errors.service && formik.touched.service && (
        <Typography sx={{ color: '#F23A3C' }}>{formik.errors.service}</Typography>
      )}
    </FormGroup>
  );
};
