import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Survey from './pages/survey';
import Dashboard from './pages/Dashboard';

export const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Survey />} />
      <Route path="/admin/*" element={<Dashboard />} />
    </Routes>
  </BrowserRouter>
);
