import { APICore } from './api.core';

const api = new APICore();

const postResponse = async (body) => {
  const { status, message, data } = await api.postAPI('/api/response', body);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getTableData = async (url, params) => {
  const { status, message, data } = await api.getAPI(url, params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getRatingDistribution = async (params) => {
  const { status, message, data } = await api.getAPI('/api/statistic/ratingDistribution', params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getNumberOfSurveys = async (params) => {
  const { status, message, data } = await api.getAPI('/api/statistic/numberOfSurveys', params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getPieRatingDistribution = async (params) => {
  const { status, message, data } = await api.getAPI('/api/statistic/pieRatingDistribution', params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getCitizenExperiences = async (params) => {
  const { status, message, data } = await api.getAPI('/api/statistic/citizenExperiences', params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

const getClientList = async (params) => {
  const { status, message, data } = await api.getAPI('/api/statistic/clientList', params);

  if (status !== 'success') {
    throw new Error(message);
  }
  return data;
};

export {
  postResponse,
  getTableData,
  getRatingDistribution,
  getNumberOfSurveys,
  getPieRatingDistribution,
  getCitizenExperiences,
  getClientList,
};
