import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getCitizenExperiences } from '../../apis/app.service';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  width: '100%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F23A3C',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '5px 0px 0px 5px',
    backgroundColor: '#00CD53',
  },
}));

export const CitizenExperiences = ({ timeFilter, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: ['CitizenExperiences'],
    queryFn: () => getCitizenExperiences({ params: { timeFilter, entityType } }),
  });

  useEffect(() => {
    refetch();
  }, [data, refetch, timeFilter, entityType]);

  if (data) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Citizen experiences" sx={{ textAlign: 'center' }} />
        <CardContent>
          {data.map((row, index) => (
            <Box sx={{ mb: 2 }} key={index}>
              <Typography>{row.key}</Typography>
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <BorderLinearProgress variant="determinate" value={row.value} />
                <Typography sx={{ ml: 2 }}>{row.value}%</Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }
};
