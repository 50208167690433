import React, { useState } from 'react';
import {
  Grid,
  Container,
  Box,
  Typography,
  FormControl,
  TextareaAutosize,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

import { REGSection, NBISection, DATSection, EGOVSection, ITSection } from '../components/survey/Sections';
import semaLogo from '../images/sema.png';
import nitaULogo from '../images/nita-u.png';
import { Formik, Form } from 'formik';
import { postResponse } from '../apis/app.service';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  category: yup.string().required().label('Category'),
  section: yup.string().required().label('Section'),
  service: yup.array().required().label('Service'),
  facedAProblem: yup.string().required().label('Faced a problem'),
  rating: yup.string().required().label('rating'),
  reason: yup.string().required().label('reason'),
  howOften: yup.string().required().label('How often'),
  gender: yup.string().required().label('Gender'),
});
const radioStyles = {
  color: '#AFC737',
  '&.Mui-checked': {
    color: '#AFC737',
  },
};

const Survey = () => {
  const [otherField, setOtherField] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    Transition: Fade,
    vertical: 'top',
    horizontal: 'center',
    severity: 'success',
    message: '',
  });

  const handleClose = () => {
    setAlertState({
      ...alertState,
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const mutation = useMutation(postResponse, {
    onSuccess: (data) => {
      //Add a snack bar message
      setAlertState({
        open: true,
        Transition: Fade,
        severity: 'success',
        message: 'Response successfully submitted',
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
    onError(error, variables, context) {
      const { message } = error;
    },
  });

  //   initialValues: {
  //     category: '',
  //     section: '',
  //     service: [],
  //     facedAProblem: '',
  //     whatChallenge: '',
  //     rating: '',
  //     reason: '',
  //     howOften: '',
  //     gender: '',
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values, actions) => {
  //     debugger;
  //     const responses = [
  //       {
  //         question_id: '63cfe0657d58d512556cf450',
  //         question_name: 'Which category do you fall in?',
  //         answer: values.category,
  //       },
  //       {
  //         question_id: '63cfe0657d58d512556cf452',
  //         question_name: 'Which section have you visited to access a service?',
  //         answer: values.section,
  //       },
  //       {
  //         question_id: '63cfe0657d58d512556cf454',
  //         question_name: 'Which service have you received/were you intending to receive?',
  //         answer: values.service.join('|'),
  //       },
  //       {
  //         question_id: '63d7a8d0f14ce0285179ec79',
  //         question_name: 'Have you faced any challenge that prolonged your service time during the online process?',
  //         answer: values.facedAProblem,
  //       },
  //       {
  //         question_id: '63cfe0657d58d512556cf456',
  //         question_name: 'If Yes, which challenge have you faced?',
  //         answer: values.whatChallenge,
  //       },
  //       {
  //         question_id: '63cfe0657d58d512556cf458',
  //         question_name: 'How would you rate this service?',
  //         answer: values.rating,
  //       },
  //       {
  //         question_id: '63cfe0667d58d512556cf45a',
  //         question_name: 'Why did you select the rating above?',
  //         answer: values.reason,
  //       },
  //       {
  //         question_id: '63cfe0667d58d512556cf45c',
  //         question_name: 'How often do you visit the NITA-U website?',
  //         answer: values.howOften,
  //       },
  //       {
  //         question_id: '63cfe0667d58d512556cf45e',
  //         question_name: 'What is your gender?',
  //         answer: values.gender,
  //       },
  //     ];

  //     mutation.mutate({
  //       survey_id: '63cfe0657d58d512556cf44e',
  //       responses,
  //     });
  //   },
  // });

  const showFormErrorAlert = (formik) => {
    if (Object.keys(formik.errors).length) {
      setAlertState({
        open: true,
        Transition: Fade,
        severity: 'error',
        message: `There are ${
          Object.keys(formik.errors).length
        } errors in the form. Please correct it before moving on`,
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: '24px', mb: '10px', display: 'flex', justifyContent: 'center' }}>
        <img src={semaLogo} alt="Sema Logo" />
        <img src={nitaULogo} alt="Sema Logo" />
      </Box>
      <Box sx={{ px: '24px', mb: '10px' }}>
        <Typography align="center" variant="h4" sx={{ color: '#2C3345', fontWeight: 'bold', fontFamily: 'Poppins' }}>
          We collect citizen feedback about public services
        </Typography>
        <Typography variant="body1" align="center">
          SEMA is aimed at encouraging and empowering citizens across the country (Uganda) to give feedback to effect
          change in the service delivery at public institutions
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: '#F3992B14',
          px: '24px',
          border: '1px solid #F3992B',
          mb: '60px',
          pt: '20px',
          borderRadius: '10px',
        }}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            category: '',
            section: '',
            service: [],
            facedAProblem: '',
            whatChallenge: '',
            rating: '',
            reason: '',
            howOften: '',
            gender: '',
          }}
          onSubmit={async (values) => {
            debugger;
            const responses = [
              {
                question_id: '63cfe0657d58d512556cf450',
                question_name: 'Which category do you fall in?',
                answer: values.category,
              },
              {
                question_id: '649c1dcd809a49b710de4b82',
                question_name: 'Name of entity?',
                answer: values.entityName,
              },
              {
                question_id: '649c1def809a49b710de4b83',
                question_name: 'Contact of entity?',
                answer: values.entityContact,
              },
              {
                question_id: '63cfe0657d58d512556cf452',
                question_name: 'Which section have you visited to access a service?',
                answer: values.section,
              },
              {
                question_id: '63cfe0657d58d512556cf454',
                question_name: 'Which service have you received/were you intending to receive?',
                answer: values.service.join('|'),
              },
              {
                question_id: '63d7a8d0f14ce0285179ec79',
                question_name:
                  'Have you faced any challenge that prolonged your service time during the online process?',
                answer: values.facedAProblem,
              },
              {
                question_id: '63cfe0657d58d512556cf456',
                question_name: 'If Yes, which challenge have you faced?',
                answer: values.whatChallenge,
              },
              {
                question_id: '63cfe0657d58d512556cf458',
                question_name: 'How would you rate this service?',
                answer: values.rating,
              },
              {
                question_id: '63cfe0667d58d512556cf45a',
                question_name: 'Why did you select the rating above?',
                answer: values.reason,
              },
              {
                question_id: '63cfe0667d58d512556cf45c',
                question_name: 'How often do you visit the NITA-U website?',
                answer: values.howOften,
              },
              {
                question_id: '63cfe0667d58d512556cf45e',
                question_name: 'What is your gender?',
                answer: values.gender,
              },
            ];

            mutation.mutate({
              survey_id: '63cfe0657d58d512556cf44e',
              responses,
            });
          }}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ display: 'block', width: '100%', mb: '10px' }}>
                <Typography>Which category do you fall in?*</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  sx={{ display: 'block' }}
                >
                  <FormControlLabel
                    value="MDA/ Entity"
                    control={<Radio sx={radioStyles} />}
                    label="MDA/ Entity"
                    sx={{
                      width: 1 / 3,
                      m: 0,
                    }}
                  />
                  <FormControlLabel
                    value="Private Entity"
                    control={<Radio sx={radioStyles} />}
                    label="Private Entity"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Citizen"
                    control={<Radio sx={radioStyles} />}
                    label="Citizen"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Civic Society Organisation"
                    control={<Radio sx={radioStyles} />}
                    label="Civic Society Organisation."
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                </RadioGroup>
                {formik.errors.category && formik.touched.category && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.category}</Typography>
                )}
              </FormControl>
              {formik.values.category !== 'Citizen' && formik.values.category ? (
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                      <Typography>Name of Entity*</Typography>

                      <TextField
                        name="entityName"
                        variant="outlined"
                        color="warning"
                        fullWidth
                        size="small"
                        value={formik.values.entityName}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                      <Typography>Contact*</Typography>

                      <TextField
                        name="entityContact"
                        placeholder="+256700000000"
                        variant="outlined"
                        color="warning"
                        fullWidth
                        size="small"
                        value={formik.values.entityContact}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}

              <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                <Typography>Which section have you visited to access a service?*</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="section"
                  value={formik.values.section}
                  onChange={formik.handleChange}
                  sx={{ display: 'block' }}
                >
                  <FormControlLabel
                    value="Regulation and compliance"
                    control={<Radio sx={radioStyles} />}
                    label="Regulation and compliance"
                    onClick={() => formik.setFieldValue('service', [])}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="NBI"
                    control={<Radio sx={radioStyles} />}
                    label="NBI"
                    onClick={() => formik.setFieldValue('service', [])}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Data Centre"
                    control={<Radio sx={radioStyles} />}
                    label="Data Centre"
                    onClick={() => formik.setFieldValue('service', [])}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="E-Government"
                    control={<Radio sx={radioStyles} />}
                    label="E-Government"
                    onClick={() => formik.setFieldValue('service', [])}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="IT Support"
                    control={<Radio sx={radioStyles} />}
                    label="IT Support"
                    onClick={() => formik.setFieldValue('service', [])}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                </RadioGroup>
                {formik.errors.section && formik.touched.section && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.section}</Typography>
                )}
              </FormControl>

              {formik.values.section === 'Regulation and compliance' ? <REGSection formik={formik} /> : null}

              {formik.values.section === 'NBI' ? <NBISection formik={formik} /> : null}

              {formik.values.section === 'Data Centre' ? <DATSection formik={formik} /> : null}

              {formik.values.section === 'E-Government' ? <EGOVSection formik={formik} /> : null}

              {formik.values.section === 'IT Support' ? <ITSection formik={formik} /> : null}

              <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                <Typography>
                  Have you faced any challenge that prolonged your service time during the online process?*
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="facedAProblem"
                  value={formik.values.facedAProblem}
                  onChange={formik.handleChange}
                  sx={{ display: 'block' }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={radioStyles} />}
                    label="Yes"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={radioStyles} />}
                    label="No"
                    onClick={() => formik.setFieldValue('whatChallenge', '')}
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                </RadioGroup>
                {formik.errors.facedAProblem && formik.touched.facedAProblem && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.facedAProblem}</Typography>
                )}
              </FormControl>

              {formik.values.facedAProblem === 'Yes' ? (
                <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                  <Typography>If Yes, which challenge have you faced?*</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="whatChallenge"
                    value={formik.values.whatChallenge}
                    onChange={formik.handleChange}
                    sx={{ display: 'block' }}
                  >
                    <FormControlLabel
                      value="Network connections"
                      control={<Radio sx={radioStyles} />}
                      label="Network connections"
                      sx={{ width: 1 / 3, m: 0 }}
                    />
                    <FormControlLabel
                      value="Code processing"
                      control={<Radio sx={radioStyles} />}
                      label="Code processing"
                      sx={{ width: 1 / 3, m: 0 }}
                    />
                    <FormControlLabel
                      value="Navigation"
                      control={<Radio sx={radioStyles} />}
                      label="Navigation"
                      sx={{ width: 1 / 3, m: 0 }}
                    />
                    <FormControlLabel
                      value="Redirected to empty page"
                      control={<Radio sx={radioStyles} />}
                      label="Redirected to empty page"
                      onClick={() => {
                        setOtherField(false);
                        formik.setFieldValue('whatChallenge', '');
                      }}
                      sx={{ width: 1 / 3, m: 0 }}
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio sx={radioStyles} />}
                      label="Other"
                      onClick={() => setOtherField(true)}
                      sx={{ width: 1 / 3, m: 0 }}
                    />
                  </RadioGroup>
                  {otherField ? (
                    <TextField
                      label=""
                      variant="outlined"
                      color="warning"
                      fullWidth
                      size="small"
                      onChange={(e) => formik.setFieldValue('whatChallenge', e.target.value)}
                    />
                  ) : null}

                  {formik.errors.whatChallenge && formik.touched.whatChallenge && (
                    <Typography sx={{ color: '#F23A3C' }}>{formik.errors.whatChallenge}</Typography>
                  )}
                </FormControl>
              ) : null}

              <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                <Typography>How would you rate the service?*</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="rating"
                  value={formik.values.rating}
                  onChange={formik.handleChange}
                  sx={{ display: 'block' }}
                >
                  <FormControlLabel
                    value="Very Bad"
                    control={<Radio sx={radioStyles} />}
                    label="Very Bad"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Bad"
                    control={<Radio sx={radioStyles} />}
                    label="Bad"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Average"
                    control={<Radio sx={radioStyles} />}
                    label="Average"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Good"
                    control={<Radio sx={radioStyles} />}
                    label="Good"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Very Good"
                    control={<Radio sx={radioStyles} />}
                    label="Very Good"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                </RadioGroup>
                {formik.errors.rating && formik.touched.rating && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.rating}</Typography>
                )}
              </FormControl>

              <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                <Typography mb="10px">Why did you give that rating*</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  style={{ width: 800 }}
                  minRows={6}
                  name="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                />
                {formik.errors.reason && formik.touched.reason && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.reason}</Typography>
                )}
              </FormControl>

              <FormControl sx={{ mb: '20px' }}>
                <Typography>How often do you visit the NITA-U website?*</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="howOften"
                  value={formik.values.howOften}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="Rarely (Once every three months)"
                    control={<Radio sx={radioStyles} />}
                    label="Rarely (Once every three months)"
                  />
                  <FormControlLabel
                    value="Often (One a month)"
                    control={<Radio sx={radioStyles} />}
                    label="Often (One a month)"
                  />
                  <FormControlLabel
                    value="Very often (Every week)"
                    control={<Radio sx={radioStyles} />}
                    label="Very often (Every week)"
                  />
                </RadioGroup>
                {formik.errors.howOften && formik.touched.howOften && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.howOften}</Typography>
                )}
              </FormControl>

              <FormControl sx={{ display: 'block', width: '100%', mb: '20px' }}>
                <Typography>What is your gender?*</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  sx={{ display: 'block' }}
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio sx={radioStyles} />}
                    label="Male"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio sx={radioStyles} />}
                    label="Female"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                  <FormControlLabel
                    value="Prefer not to say"
                    control={<Radio sx={radioStyles} />}
                    label="Prefer not to say"
                    sx={{ width: 1 / 3, m: 0 }}
                  />
                </RadioGroup>
                {formik.errors.gender && formik.touched.gender && (
                  <Typography sx={{ color: '#F23A3C' }}>{formik.errors.gender}</Typography>
                )}
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{ bgcolor: '#00CD53', mb: '20px', px: '45px' }}
                  type="submit"
                  onClick={() => showFormErrorAlert(formik)}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertState.open}
        autoHideDuration={3000}
        TransitionComponent={alertState.Transition}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertState.severity} sx={{ width: '100%' }}>
          {alertState.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Survey;
