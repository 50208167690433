import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getNumberOfSurveys } from '../../apis/app.service';

export const NumberOfSurveys = ({ timeFilter, entityType }) => {
  const { data, refetch } = useQuery({
    queryKey: ['numberOfSurveys'],
    queryFn: () => getNumberOfSurveys({ params: { timeFilter, entityType } }),
  });

  const ref = useRef(null);

  useEffect(() => {
    refetch();
    if (!ref.current) {
      return;
    }

    const chart = new ApexCharts(ref.current, getChartOptions(data));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, refetch, timeFilter, entityType]);

  if (data) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Number of Survey" sx={{ textAlign: 'center' }} />
        <CardContent>
          <div ref={ref}></div>
        </CardContent>
      </Card>
    );
  }
};

const getChartOptions = (series) => {
  return {
    chart: {
      type: 'bar',
      fontFamily: 'inherit',
      height: 350,
      toolbar: {
        show: true,
      },
    },
    colors: ['#00CD53'],
    plotOptions: {
      bar: {
        horizontal: false,
        toolbar: {
          show: true,
        },
      },
    },
    series: [
      {
        data: series,
      },
    ],
    grid: {
      strokeDashArray: 0,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 5,
      },
    },
  };
};
